import React, { Fragment } from "react";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Outlet } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import Sidebar from "../sidebar/Sidebar";
import './style.scss'

const Layout = () => {
    return (

        <div className="content">

            <Sidebar toggle={false} />

            <div className="customerDashboard__homeContainer App">
                <Navbar pageTitle="" />
                <Outlet />
            </div>
        </div>

    );
};

export default Layout;
