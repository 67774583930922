import "./datatable.scss";
//import { DataGrid, GridToolbar } from "@mui/x-data-grid";
//import LinearProgress from '@mui/material/LinearProgress';
import 'react-data-grid/lib/styles.css';
import { Spinner } from "reactstrap"
import DataGrid from 'react-data-grid';

//import { userColumns, userRows } from "../../datatablesource";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { Form, Tooltip } from 'reactstrap'
// import Single from "../../pages/admin/users/customers/view/Single";

//import ProductDetails from '../../pages/admin/product/view/ProductDetails'
//import Car from "../Forms/car/car";

const Datatable = (props) => {


    useEffect(() => {
        console.log("loading...." + props.loading)
    }, [props.loading])


    const onPageChange = (e) => {
        console.log(e)
        props.setPageNum(e)
    }


    return (

        <div className="datatable App">
            {
                // props.loading ?

                //     <Spinner style={{ width: '2rem', height: '2rem', position: "absolute", left: "50%" }} children={false} />
                //     :
                <DataGrid
                    className="datagrid"
                    rows={props.data}
                    rowCount={props.pageSize}
                    columns={props.columns}
                />
            }

            {/* {props.showPagination && <div>

                <Pagination
                    onPageChange={onPageChange}
                    pageSizes={props.pageSizes}
                    setPageSize={props.setPageSize}
                    pages={props.totalPages}
                    page={props.pageNum}

                    previousText={"prev"}
                    nextText={"next"} />
            </div>} */}
        </div>
    );
};

export default Datatable;
