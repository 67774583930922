import React, { useEffect, useState, useContext, useCallback, Fragment } from 'react'
import { Spinner } from "reactstrap"
import Datatable from '../../../../components/Table/Datatable';
// import { APIContext } from '../../../../context/APIContext';
//import { connect } from 'react-redux'
// import {
//     showErrorMessage,
//     showSuccessMessage,
//     dismissErrorMessage,
//     dismissSuccessMessage,
//     updateLoading
// } from '../../../../redux/actions/tunnel';

import { formatCurrency } from '../../../../utilities/formatCurrency'
import TableToolbar from './components/table-toolbar/TableToolbar';
import './style.scss'

function PaymentHistory(props: any) {
    const d = [
        {
            id: "DNSKOOEPOADFFNL",
            source: "jdnfvjkndfnkdfml",
            date: "24th January,2024",
            amount: 3500
        },
        {
            id: "DNPOADFWUEYUR",
            source: "jdnfvjkndfnkdfml",
            date: "24th January,2024",
            amount: 3500
        },
        {
            id: "DNSDJFJNDNFLDFD",
            source: "jdnfvjkndfnkdfml",
            date: "24th January,2024",
            amount: 3500
        },
        {
            id: "DNSIWOEROIEHRIOWR",
            source: "jdnfvjkndfnkdfml",
            date: "24th January,2024",
            amount: 3500
        },

    ]
    const [data, setData] = useState(d);
    const [loading, setLoading] = useState(true)
    const [pageSize, setPageSize] = useState(10) // 10 pages
    const [pageNum, setPageNum] = useState(0)
    const [totalPages, setTotalPages] = useState(1)
    const [filter, setFilter] = useState({ pageSize })

    // const [authUser, setAuthUser] = useContext(AuthUserContext)
    // const state = useContext(APIContext)
    // const { getCarList, searchCars, deleteCar, deleteAll } = state.CarAPI;

    const pageSizes = [10, 50, 100, 150, 200, 250, 500, 1000];
    interface TableActionProps {
        value: string;
        row: any
    }

    const userColumns = [
        {
            key: "id",
            name: "Transaction ID",
            // width: 300
        },
        {
            key: "source",
            name: "Source",
            // width: 200,
            resizable: true,
        },
        {
            key: "date",
            name: "Date",
            width: 150,
            resizable: true,
            renderCell: (tableActionProps: TableActionProps) => {

                return (

                    <div className="cellWithImg" style={{}} >
                        <span style={{ width: "12px" }}>{tableActionProps.row.date}</span>
                    </div>

                );
            },
        },

        {
            key: "amount",
            name: "Amount",
            // width: 150,
            resizable: true,
            renderCell: (tableActionProps: TableActionProps) => {
                return (
                    <div className={``}>
                        {formatCurrency(tableActionProps.row.amount)}
                    </div>
                );
            },

        },

        // {
        //     key: "action",
        //     name: "Action",
        //     width: 100,
        //     renderCell: ({ value, row }) => {
        //         return (
        //             <div className="cellAction">
        //                 {/* <Link to={`/users/customers/view/${params.row.id}`} style={{ textDecoration: "none" }}>
        //                     <div className="viewButton">View</div>
        //                 </Link> */}
        //                 <div
        //                     className="viewButton"
        //                     onClick={
        //                         () => props.onClick(
        //                             { name: "view", path: `/view/${row._id}`, content: props.configs.filter(config => config.name == "view")[0].content }
        //                         )
        //                     }
        //                 // style={{ curson: "pointer" }}
        //                 >
        //                     {/* View */}
        //                     {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z" /><path d="M16.757 3l-2 2H5v14h14V9.243l2-2V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12.757zm3.728-.9L21.9 3.516l-9.192 9.192-1.412.003-.002-1.417L20.485 2.1z" /></svg> */}
        //                     <i className="ri-edit-box-line"></i>
        //                 </div>
        //                 <div
        //                     className="deleteButton"
        //                     onClick={() => handleDelete(row.id)}
        //                 >
        //                     {/* <svg className="deleteButton" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z" /><path d="M17 6h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3zm1 2H6v12h12V8zm-9 3h2v6H9v-6zm4 0h2v6h-2v-6zM9 4v2h6V4H9z" /></svg> */}
        //                     <i className="ri-delete-bin-line"></i>
        //                 </div>
        //             </div >
        //         );
        //     },
        // },
    ];

    const handleDelete = (id: number) => { }



    // useEffect(() => {
    //     filter.pageNum = pageNum;
    //     filter.pageSize = pageSize;
    //     // carList(filter)
    // }, [pageNum, pageSize, filter])


    // const carList = useCallback((filter) => {
    //     setLoading(true)
    //     setData([])//set it to empty

    //     searchCars(filter).then(

    //         ({ data, status, code }) => {

    //         },
    //         (error) => {

    //         }
    //     )
    // }, [pageNum, pageSize]
    // )


    // const handleReload = () => {
    //     carList(filter);
    // }




    return (

        <Fragment>
            <TableToolbar
                onClick={props.onClick}
                configs={props.configs}
                setFilter={props.setFilter}
                handleReload={props.handleReload}
                handleDeleteAll={props.handleDeleteAll}

                header={props.columns}
                getDownloadData={props.getDownloadData}
                fileName={props.fileName}
                showFilterAdd={props.showFilterAdd}
                showFilterDateOnly={props.showFilterDateOnly}
                showFilterDelete={props.showFilterDelete}

            />

            <Datatable
                data={data}
                columns={userColumns}
                configs={props.configs}
                onClick={props.onClick}
                loading={loading}
                setFilter={setFilter}
                pageSizes={pageSizes}
                pageSize={pageSize}
                setPageSize={setPageSize}
                pageNum={pageNum}
                setPageNum={setPageNum}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                showFilter={true}
            />

            <div className='see-more' >{"See more"}</div>
        </Fragment>
    )


}




export default PaymentHistory