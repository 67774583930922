import React, { useState, useEffect, useContext } from 'react'
import { Link } from "react-router-dom";
import { ActivePageContext } from '../../context/ActivePageContext'
import "./sidebar.scss";

import CustomTooltip from "../Tooltip/Tooltip";
import logo from "../../assets/images/logo.svg";

type Args = {
    toggle: boolean;
};


function Sidebar(props: Args) {


    const labels = {
        mainpages: "Main pages",
        dashboard: "Dashboard",
        closeSidebar: "Close Sidebar",
        openSidebar: "Open Sidebar",
        settings: "Settings",
        logout: "Logout",
        balances: "Balances",
        customers: "Customers",
        analytics: "Analytics",
        general: "General",
        contact: "Contact"
    }

    const [activeSidebar, setActiveSidebar] = useState('dashboard')
    const currentPageContext = useContext(ActivePageContext);
    const [toggle, setToggle] = useState(false)


    const handleOnClick = (e: any, className: string) => {
        setActiveSidebar(className)
        currentPageContext?.setActivePage(className)

        const nodeList = document.querySelectorAll(".icon");
        for (let i = 0; i < nodeList.length; i++) {
            nodeList[i].classList.remove('active');

        }

        document.querySelector(`#${className} i`)?.classList.add("active")
        // document.querySelector(`#${className} span`).classList.add("active")

    }



    return (
        <div className='sidebar' >
            <ul>
                <li className='clear'>
                    <img
                        src={
                            logo//URL.createObjectURL(file)
                        }
                        alt=""

                    />
                </li>
                <p className={`title clear`}>{labels.mainpages}</p>

                <Link to="/dashboard" style={{ textDecoration: "none" }} id={"dashboard"} onClick={(e) => handleOnClick(e, "dashboard")}>
                    {props.toggle && <CustomTooltip targetElementId={"dashboard"} text={labels.dashboard} position="right" />}
                    <li className={currentPageContext?.activePage == "dashboard" ? "selectedTab" : "caption"}>
                        <i className="ri-dashboard-line ri-1x icon" ></i>
                        <span className='clear'>{labels.dashboard}</span>
                        {activeSidebar == "dashboard" && <span className='selectedTab'></span>}
                    </li>

                </Link>

                <Link to="/balances" style={{ textDecoration: "none" }} id={"balances"} onClick={(e) => handleOnClick(e, "balances")}>
                    <li className={currentPageContext?.activePage == "balances" ? "selectedTab" : "caption"}>
                        {/* <i class={`ri-hard-drive-2-line icon ${props.toggle ? "ri-1x" : ""}`}></i> */}
                        <i className={`ri-wallet-3-line icon ${props.toggle ? " ri-1x" : ""}`}></i>
                        {/* <StoreIcon className="icon" /> */}
                        <span className='clear'>{labels.balances}</span>
                        {props.toggle && <CustomTooltip targetElementId={"balances"} text={labels.balances} position="right" />}
                        {activeSidebar == "balances" && <span className='selectedTab'></span>}
                    </li>
                </Link>

                <Link to="/customers" style={{ textDecoration: "none" }} id={"customers"} onClick={(e) => handleOnClick(e, "customers")}>

                    <li id={labels.customers} className={currentPageContext?.activePage == "customers" ? "selectedTab" : "caption"}>
                        <i className={`ri-user-3-line icon ${props.toggle ? " ri-1x" : ""} `}></i>
                        <span className='clear' >{labels.customers}</span>
                        {props.toggle && <CustomTooltip targetElementId={"customers"} text={labels.customers} position="right" />}
                        {activeSidebar == "customers" && <span className='selectedTab'></span>}
                    </li>
                </Link>

                <Link to="/analytics" style={{ textDecoration: "none" }} id={"analytics"} onClick={(e) => handleOnClick(e, "analytics")}>

                    <li id={"analytics"} className={currentPageContext?.activePage == "analytics" ? "selectedTab" : "caption"}>
                        <i className={`ri-funds-box-line  icon ${props.toggle ? " ri-1x" : ""}`}></i>
                        <span className='clear' >{labels.analytics}</span>
                        {props.toggle && <CustomTooltip targetElementId={"analytics"} text={labels.analytics} position="right" />}
                        {activeSidebar == "analytics" && <span className='selectedTab'></span>}
                    </li>
                </Link>
                <p className='clear'>{labels.general}</p>
                <Link to="/settings" style={{ textDecoration: "none" }} id={"settings"} onClick={(e) => handleOnClick(e, "settings")}>

                    <li id={labels.settings} className={currentPageContext?.activePage == "settings" ? "selectedTab" : "caption"}>
                        <i className={`ri-equalizer-2-line icon ${props.toggle ? "ri-1x" : ""}`}></i>
                        <span className='clear'>{labels.settings}</span>
                        {props.toggle && <CustomTooltip targetElementId={"settings"} text={labels.settings} position="right" />}
                        {activeSidebar == "settings" && <span className='selectedTab'></span>}
                    </li>
                </Link>

                <Link to="/contact" style={{ textDecoration: "none" }} id={"contact"} onClick={(e) => handleOnClick(e, "contact")}>

                    <li id={labels.contact} className={currentPageContext?.activePage == "contact" ? "selectedTab" : "caption"}>
                        <i className={`ri-phone-line icon ${props.toggle ? "ri-1x" : ""}`}></i>
                        <span className='clear'>{labels.contact}</span>
                        {props.toggle && <CustomTooltip targetElementId={"contact"} text={labels.contact} position="right" />}
                        {activeSidebar == "contact" && <span className='selectedTab'></span>}
                    </li>
                </Link>

                <Link to="/logout" style={{ textDecoration: "none" }} id={labels.logout} >
                    <li id={labels.logout} className={currentPageContext?.activePage == "logout" ? "selectedTab" : "caption"}>
                        <i className={`ri-logout-box-r-line icon ${props.toggle ? "ri-1x" : ""}`}></i>
                        {/* <ExitToAppIcon className="icon" /> */}
                        <span className='clear'>{labels.logout}</span>
                        {props.toggle && <CustomTooltip targetElementId={labels.logout} text={labels.logout} position="right" />}
                        {activeSidebar == "logout" && <span className='selectedTab'></span>}
                    </li>
                </Link>
            </ul>

        </div>
    )




}

export default Sidebar;