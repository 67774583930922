import { createContext, useState, ReactNode } from "react";

interface ContextType {
    activePage: string;
    setActivePage: React.Dispatch<React.SetStateAction<string>>;
}
export const ActivePageContext = createContext<ContextType | undefined>(undefined);

interface MyContextProviderProps {
    children: ReactNode;
}
export const ActivePageContextProvider: React.FC<MyContextProviderProps> = ({ children }) => {
    let [activePage, setActivePage] = useState<string>("dashboard");

    const contextValue: ContextType = {
        activePage,
        setActivePage,
    };

    return (
        <ActivePageContext.Provider value={contextValue} >
            {children}
        </ActivePageContext.Provider>
    )
}

