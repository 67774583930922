import React, { Fragment } from 'react';
import Routers from "./routers/Routers";
import { BrowserRouter as Router } from "react-router-dom";
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <Fragment>
      <Router>
        <Routers />
      </Router>
    </Fragment>
  );
}

export default App;
