import React, { useState } from 'react'
import { Form, FormGroup, Input, Label, Tooltip, Button } from 'reactstrap'
import SearchBar from '../search-bar/searchBar'
import CustomTooltip from "../../../../../../components/Tooltip/Tooltip";
import './style.scss'

function TableToolbar(props) {

    const [loading, setLoading] = useState(false)
    const [tooltipOpen, setTooltipOpen] = useState(false)
    const [tooltipDownloadOpen, setTooltipDownloadOpen] = useState(false)

    const handleSubmit = () => {

    }

    const handleChange = (event) => {

    };

    return (

        <div className='tableToolbar'>
            <div style={{ marginTop: '30px', fontWeight: '900' }}>{"Payment History Table"}</div>
            <SearchBar setFilter={props.setFilter} showFilterDateOnly={props.showFilterDateOnly} />
        </div >

    )
}

export default TableToolbar