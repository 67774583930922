import React, { useEffect, useContext } from 'react'
import Balance from './components/account-balance/Balance'
import PaymentHistory from './components/payment-history/paymentHistory'
import { ActivePageContext } from '../../context/ActivePageContext'
import './style.scss'

function Balances() {
    const currentPageContext = useContext(ActivePageContext);

    useEffect(() => {

        var url = new URL(window.location + "");
        const path = url.pathname
        console.log("url path:" + path)
        var activeSidebarOption = "balances";
        if (path.includes("balances")) {
            console.log("page:" + activeSidebarOption)
            currentPageContext?.setActivePage(activeSidebarOption)
        }


    }, [])

    return (
        <div className='dashboard'>
            <Balance />
            <PaymentHistory />
        </div>
    )
}

export default Balances