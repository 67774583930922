import React, { useState } from 'react'
import { Container, Form, Row, Col, Label, FormGroup, Input, InputGroup } from "reactstrap";
import { formatCurrency } from '../../../../utilities/formatCurrency'
import './style.scss'

function Balance() {

    const initialState = {
        currencies: ['NGN', 'USD', 'KES', 'GHC'],
        amount: 550637,
        exchangeRate: "1USD = 1250.97NGN",
        fundsOnHold: 484871
    };
    const [formData, setFormData] = useState({
        ...initialState
    })




    const handleUpdate = () => {
        return null;
    }

    const { currencies, amount, exchangeRate, fundsOnHold } = formData

    return (
        <Form onSubmit={handleUpdate}>
            <Row className='balance-wrapper'>
                <Col lg="6" md="6" xl={6} className="current-balance">
                    <div className='totalBalance'>
                        <Label className='total-acc-balance'>{"Total account balance"}</Label>
                        <FormGroup id="currency">
                            <Input type="select" defaultValue={currencies[0]} disabled={false} className='currencies'>
                                {
                                    currencies.map(
                                        (currency) => {
                                            return <option value={currency}>{currency}</option>
                                        }
                                    )
                                }
                            </Input>
                        </FormGroup>
                    </div>

                    <div style={{ textAlign: 'left' }}>
                        <div className='figures'>{formatCurrency(amount)}</div>
                        <div className='exchange-rate'>{exchangeRate}</div>
                    </div>

                </Col>
                <Col lg="6" md="6" xl={6} className='fundsOnHold'>
                    <div style={{ textAlign: 'left', fontWeight: 500 }}>{"Funds On hold"}</div>
                    <div className='figures'>{formatCurrency(fundsOnHold)}</div>
                </Col>
            </Row>
        </Form>
    )
}

export default Balance