import React, { useState } from 'react'
import { Form, FormGroup, Input, Label, Tooltip, Button, InputGroup, InputGroupText } from 'reactstrap'
import { formatDate } from '../../../../../../utilities/formatDateTime'
import './style.scss'
function SearchBar(props) {

    const [loading, setLoading] = useState(false)
    const initialState = {
        paymentId: "",
        date: "",
    };

    const [formData, setFormData] = useState({
        ...initialState
    });


    const { paymentId, date } = formData;


    const handleSubmit = (e) => {
        e.preventDefault();
        const newFormData = { ...formData };
        const date = newFormData.date != "" ? new Date(formatDate(newFormData.date)) : "";
        delete newFormData.date;
        // console.log("from: " + formatedFrom + "  =  to:" + formatedTo)
        if (!paymentId)
            delete newFormData.paymentId
        if (!date)
            delete newFormData.date

        // props.setFilter(newFormData)
    }

    const handleChange = (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;

        //console.log("date :" + value)

        //test if field has value
        setFormData((prevState) => {
            return {
                ...prevState,
                [name]: value,
            };
        });
    };


    return (
        <Form onSubmit={(e) => handleSubmit(e)} className="tableToolbar-wrapper">

            <FormGroup className="table-form-group mt-3">
                <InputGroup>
                    <Input
                        type="text"
                        name="paymentId"
                        id="paymentId"
                        // placeholder="parentCompanyName"
                        className="form-control mt-1"
                        value={paymentId}
                        onChange={(e) => {
                            handleChange(e)
                        }}
                        placeholder='Search Something...'
                    //invalid={formError.parentCompanyName != null}
                    // required
                    />
                    <InputGroupText >
                        <i class="ri-search-line" style={{ fontSize: '10px' }}></i>
                    </InputGroupText>
                </InputGroup>
            </FormGroup>


            <FormGroup className="table-form-group mt-3">
                <Input
                    type="date"
                    name="date"
                    id="date"
                    //dateFormat="YYYY/MM/DD"
                    // placeholder="parentCompanyName"
                    className="form-control mt-1"
                    value={date}
                    onChange={(e) => {
                        handleChange(e)
                    }}
                //invalid={formError.parentCompanyName != null}
                // required
                />
            </FormGroup>

            <FormGroup className="table-form-group mt-3">
                <Button
                    type="submit"
                    // color="primary"
                    className='submit_btn'
                    // style={{ marginLeft: "", marginBottom: "", borderRadius: "5px",width }}
                    id={"submit"}

                //  disabled={!valid}
                >
                    {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                    )}
                    Payout
                </Button>
            </FormGroup>
        </Form>
    )
}

export default SearchBar