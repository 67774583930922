import React, { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import { ActivePageContextProvider } from '../context/ActivePageContext'
import Balances from '../pages/Balances/Balances';
import Dashboard from "../pages/Dashboard/Dashboard";
import './style.scss';
const Routers = () => {

    return (
        <div className="main-wrapper">
            <ActivePageContextProvider>
                <Routes >
                    <Route path="/" element={<Layout />}>
                        <Route index path="dashboard" element={<Dashboard />} />
                        <Route path="balances" element={<Balances />} />
                        {/* <Route path="settings" element={<Setting/>} /> */}
                        {/* <Route path="logout" element={<Logout/>} /> */}
                    </Route>
                </Routes>
            </ActivePageContextProvider>
        </div>
    )
}

export default Routers;