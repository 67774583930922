import { useState, useContext } from "react";
import { ActivePageContext } from "../../context/ActivePageContext"
import "./navbar.scss";
import { Button, Container, Nav, Navbar as NavbarBs } from "reactstrap"
import { formatDateV2 } from '../../utilities/formatDateTime'

type Args = {
    pageTitle: string,
}
const Navbar = (props: Args) => {
    const contextValue = useContext(ActivePageContext);
    const totalCount = 0;
    const date = new Date();

    return (
        <NavbarBs sticky="top" className="bg-white shadow-sm mb-3 ">
            <div className="wrapper">
                <div>
                    <div className="activePage">{contextValue?.activePage}</div>
                    <div className="currentDate" >{"Today:" + formatDateV2(date, 'Do MMMM YYYY')}</div>
                </div>
                <div className="items">

                    <div className="item iconSection">
                        {/* <i className="ri-moon-line icon"  ></i> */}
                        <i className="ri-notification-line icon" style={{ cursor: "pointer" }} ></i>
                        <span className={"iconBadge"}>{totalCount}</span>

                    </div>

                    <div className="item">

                    </div>

                    <div className="item">
                        <img
                            src="https://firebasestorage.googleapis.com/v0/b/ninja-firegram-80354.appspot.com/o/temiProfile.jpg?alt=media&token=e94a5621-e110-4c5b-b872-247a75efcf16"
                            //src="https://images.pexels.com/photos/941693/pexels-photo-941693.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
                            alt=""
                            className="avatar"
                        />
                    </div>
                </div>


            </div>

        </NavbarBs>
    );
}

export default Navbar;