import React, { useState } from 'react'
import { Tooltip } from 'reactstrap'


function CustomTooltip(arg: Args) {

    const [tooltipOpen, setTooltipOpen] = useState(false)

    return (
        <Tooltip placement={arg.position} isOpen={tooltipOpen} target={arg.targetElementId} toggle={() => { setTooltipOpen((prev) => (!prev)) }}>
            {arg.text}
        </Tooltip>
    )
}

type Args = {
    text: string;
    targetElementId: string;
    position: any;
};

export default CustomTooltip